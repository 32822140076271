import React, { useEffect, useState, useRef, useMemo } from 'react';
import PerformanceCheckReportHeader from './PerformanceCheckReportHeader';

const PAGE_HEIGHT = 180; // mm

const PaginatedRepairHistory = ({ workDetails, repairDetails }) => {
  const [pages, setPages] = useState([]);

  const mmToPx = (mm) => mm * 3.7795275591;

  // 데이터를 페이지별로 나누는 함수를 useMemo로 최적화
  const splitDataIntoPages = useMemo(() => {
    const maxPageHeightPx = mmToPx(PAGE_HEIGHT);
    const headerHeight = mmToPx(30);
    const titleHeight = mmToPx(10);
    const dateHeight = mmToPx(4);
    const itemHeight = mmToPx(4);

    const allPages = [];
    let currentPage = {
      workDetails: {
        lastDateIndex: -1,
        lastItemIndex: -1,
        items: [],
      },
      repairDetails: {
        lastDateIndex: -1,
        lastItemIndex: -1,
        items: [],
      },
    };

    let currentHeight = headerHeight + titleHeight;

    // 두 배열 모두 처리가 끝났는지 확인하는 함수
    const isProcessingComplete = (
      workIdx,
      workItemIdx,
      repairIdx,
      repairItemIdx
    ) => {
      const workComplete =
        workIdx >= workDetails.length ||
        (workIdx === workDetails.length - 1 &&
          workItemIdx >= workDetails[workIdx].items.length);
      const repairComplete =
        repairIdx >= repairDetails.length ||
        (repairIdx === repairDetails.length - 1 &&
          repairItemIdx >= repairDetails[repairIdx].items.length);
      return workComplete && repairComplete;
    };

    let workIdx = 0,
      workItemIdx = 0;
    let repairIdx = 0,
      repairItemIdx = 0;

    while (
      !isProcessingComplete(workIdx, workItemIdx, repairIdx, repairItemIdx)
    ) {
      // 새 페이지 시작시 현재 높이 초기화
      if (currentHeight >= maxPageHeightPx) {
        allPages.push(currentPage);
        currentPage = {
          workDetails: {
            lastDateIndex: workIdx,
            lastItemIndex: workItemIdx - 1,
            items: [],
          },
          repairDetails: {
            lastDateIndex: repairIdx,
            lastItemIndex: repairItemIdx - 1,
            items: [],
          },
        };
        currentHeight = headerHeight + titleHeight;
      }

      // 정비이력 처리
      if (workIdx < workDetails.length) {
        if (workItemIdx === 0) {
          // 날짜 추가
          currentPage.workDetails.items.push({
            type: 'date',
            content: workDetails[workIdx].inDay,
          });
          currentHeight += dateHeight;
        }

        if (workItemIdx < workDetails[workIdx].items.length) {
          // 아이템 추가
          currentPage.workDetails.items.push({
            type: 'item',
            content: workDetails[workIdx].items[workItemIdx],
          });
          currentHeight += itemHeight;
          workItemIdx++;
        } else {
          workIdx++;
          workItemIdx = 0;
        }
      }

      // 카센터 정비이력 처리
      if (repairIdx < repairDetails.length) {
        if (repairItemIdx === 0) {
          // 날짜 추가
          currentPage.repairDetails.items.push({
            type: 'date',
            content: repairDetails[repairIdx].inDay,
          });
          currentHeight += dateHeight;
        }

        if (repairItemIdx < repairDetails[repairIdx].items.length) {
          // 아이템 추가
          currentPage.repairDetails.items.push({
            type: 'item',
            content: repairDetails[repairIdx].items[repairItemIdx],
          });
          currentHeight += itemHeight;
          repairItemIdx++;
        } else {
          repairIdx++;
          repairItemIdx = 0;
        }
      }
    }

    // 마지막 페이지 추가
    if (
      currentPage.workDetails.items.length > 0 ||
      currentPage.repairDetails.items.length > 0
    ) {
      allPages.push(currentPage);
    }

    return allPages;
  }, [workDetails, repairDetails]);

  useEffect(() => {
    setPages(splitDataIntoPages);
  }, [splitDataIntoPages]);

  return (
    <>
      {pages.map((pageData, pageIndex) => (
        <div key={`page_${pageIndex}`} className="paper_container">
          <div className="paper flex flex-column">
            <div className="watermark"></div>
            <PerformanceCheckReportHeader mini={true} />
            <div className="section_title_new">
              <h4>
                종합이력 ({pageIndex + 1}/{pages.length})
              </h4>
            </div>
            <div
              className="flex-1 grid col-12 text-md p-2 mt-4 mb-2"
              style={{ height: `${PAGE_HEIGHT}mm` }}
            >
              <div className="col-4">
                <h5 className="font-bold m-0 mb-4">차량 정비이력</h5>
                <div>
                  {pageData.workDetails.items.map((item, index) => (
                    <div key={`workDetail_${pageIndex}_${index}`}>
                      {item.type === 'date' ? (
                        <p className="font-bold mb-1 mt-3">{item.content}</p>
                      ) : (
                        <p className="p-0 m-0 ml-1 mb-1">{item.content}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-4">
                <h5 className="font-bold m-0 mb-4">카센터 정비이력</h5>
                <div>
                  {pageData.repairDetails.items.map((item, index) => (
                    <div key={`repairDetail_${pageIndex}_${index}`}>
                      {item.type === 'date' ? (
                        <p className="font-bold mb-1 mt-3">{item.content}</p>
                      ) : (
                        <p className="p-0 m-0 ml-1 mb-1">{item.content}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-4">
                <h5 className="font-bold m-0 mb-4">보험이력</h5>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PaginatedRepairHistory;
