import React, { useEffect, useState } from 'react';

const SvgComponent = ({ parts, partPoint, partState, partName }) => {
  // 각 path의 상태를 관리
  const [item, setItem] = useState('');

  // console.log('parts', parts);
  // console.log('partPoint', partPoint);
  // console.log('partState', partState);

  const getPartColor = (value) => {
    switch (value) {
      case '1':
        return 'transparent'; // default color for '1'
      case '2':
        return '#D32F2F'; // 교환 (X)
      case '3':
        return '#2296F3'; // 판금 (W)
      // case '4':
      //   return '#BA5900'; // 부식 (C)
      // case '5':
      //   return '#75C9E9'; // 흠집 (A)
      // case '6':
      //   return '#717171'; // 요철 (U)
      // case '7':
      //   return '#01516F'; // 손상 (T)
      default:
        return 'transparent'; // 기본 색상
    }
  };

  useEffect(() => {
    const item = parts.find((item) => item.partPoint === partPoint);
    setItem(item);
  }, [partPoint, parts]);

  useEffect(() => {
    // console.log('item', item);
  }, [item]);

  const BOTTOM = '0 0 655 1200';
  const TOP = '0 0 655 1000';
  const RIGHT = '25 155 650 1000'; // 오른쪽 값 예시
  const LEFT = '-10 155 650 1000'; // 왼쪽 값 예시

  function getViewBox(partName) {
    switch (partName) {
      case 'PARTS_BOTTOM':
        return BOTTOM;
      case 'PARTS_TOP':
        return TOP;
      case 'PARTS_RIGHT':
        return RIGHT;
      case 'PARTS_LEFT':
        return LEFT;
      default:
        return '0 0 655 1200'; // 기본값을 설정 (필요에 따라 수정)
    }
  }

  return (
    <svg
      viewBox={getViewBox(partName)}
      width="100%"
      height="100%"
      opacity={0.6}
    >
      <g
        fill={getPartColor(partState)}
        dangerouslySetInnerHTML={{ __html: item?.element }}
      />
    </svg>
  );
};

export default SvgComponent;
