import React, { forwardRef } from 'react';

import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

export const TitledInputNumber = forwardRef((props, ref) => {
  const {
    id,
    title = '',
    value,
    onChange,
    readOnly = false,
    disabled = false,
    useGrouping = true,
    suffix = null,
    className = '',
    error = null,
    required = false,
    selectOnFocus = false,
    min,
    max,
  } = props;

  return (
    <div className="field m-0">
      <label htmlFor={`num_${id}`}>
        {required && <i className="text-red-400 pi pi-check mr-1" />}
        {title}
      </label>
      <InputNumber
        id={id}
        inputRef={ref}
        className="w-full"
        inputClassName={classNames('w-full text-right ' + className, {
          'p-invalid': error?.message,
          'bg-gray-100': readOnly && className === '',
          [className]: readOnly && className !== '',
        })}
        readOnly={readOnly}
        disabled={disabled}
        useGrouping={useGrouping}
        value={value}
        onChange={onChange && ((e) => onChange(e.value))}
        suffix={suffix ? ` ${suffix}` : ''}
        onFocus={selectOnFocus ? (e) => e.target.select() : null}
        {...(min && { min })}
        {...(max && { max })}
      />
      <small id={`num_err_${id}`} className="p-error">
        {error?.message}
      </small>
    </div>
  );
});
