import {
  PARTS_BOTTOM,
  PARTS_LEFT,
  PARTS_RIGHT,
  PARTS_TOP,
  PC_BASE_BLUEPRINT,
  PC_STATUS_BLUEPRINT,
  PC_ADD_BLUEPRINT,
  ADD_PARTS_LEFT,
  ADD_PARTS_RIGHT,
  ADD_PARTS_TOP,
  ADD_PARTS_INNER,
  ADD_PARTS_STATUS,
  PC_VALID_DAYS,
  PHOTO_TYPE,
} from '../../constants/PerformanceCheckConstants';
import React, { forwardRef, useEffect, useState } from 'react';
import { myInfoSelector } from '../../recoil/selectors';
import { useRecoilValueLoadable } from 'recoil';
// import { CERTIFICATE_DATAS } from '../../constants/InsuranceDataConstants';
import { CommonUtil } from '../../utils/commonUtil';
// import { EW_CERTIFICATE_DATAS } from '../../constants/EWDataConstants';
import { ServiceProvider } from '../../services';
// import { YN } from '../../constants/Constants';
import _ from 'lodash';
import dayjs from 'dayjs';
import Checked from '../../assets/icons/checked.webp';
import Unchecked from '../../assets/icons/unchecked.webp';
import BPBottom from '../../assets/images/blueprint/bp-bottom.webp';
import BPLeft from '../../assets/images/blueprint/bp-left.webp';
import BPRight from '../../assets/images/blueprint/bp-right.webp';
import BPTop from '../../assets/images/blueprint/bp-top.webp';
import BPInner from '../../assets/images/blueprint/bp-inner.webp';
import IconStatus1 from '../../assets/images/icon-status1.svg';
import IconStatus2 from '../../assets/images/icon-status2.svg';
import { YN } from '../../constants/Constants';
import { EW_CERTIFICATE_DATAS } from '../../constants/EWDataConstants';
import { CERTIFICATE_DATAS } from '../../constants/InsuranceDataConstants';
import { Image } from 'primereact/image';
import { Badge } from 'primereact/badge';
import PerformanceCheckReportHeader from './PerformanceCheckReportHeader';
import SvgComponent from './SvgComponent';
import SvgComponent2 from './SvgComponent2';
import PaginatedRepairHistory from './PaginatedRepairHistory';

const baseTitles = _.mapValues(PC_BASE_BLUEPRINT, 'title');
const baseValueItems = _.mapValues(PC_BASE_BLUEPRINT, 'valueItems');
const statusTitles = _.mapValues(PC_STATUS_BLUEPRINT, 'title');
const statusValueItems = _.mapValues(PC_STATUS_BLUEPRINT, 'valueItems');
const addValueItems = _.mapValues(PC_ADD_BLUEPRINT, 'valueItems');
const associationService = ServiceProvider.association;
const userService = ServiceProvider.user;

const setCheckState = (raw, key, compare) => {
  if (_.isArray(compare))
    return _.includes(compare, _.get(raw, key)) ? Checked : Unchecked;
  return _.get(raw, key) === compare ? Checked : Unchecked;
};

const setSubCheckState = (raw, keys, compares) => {
  let count = 0;
  for (const key of keys) {
    if (_.includes(compares, _.get(raw, key))) count++;
  }
  return count === 0 ? Unchecked : Checked;
};

const setPartState = (value) => {
  let label = '';

  switch (value) {
    case '1':
      return '';
    case '2':
      return 'X';
    case '3':
      return 'W';
    case '4':
      return 'C';
    case '5':
      return 'A';
    case '6':
      return 'U';
    case '7':
      return 'T';
    default:
      break;
  }

  return label;
};

const formatDate = (date) => {
  if (!date) return '년     월     일';
  return dayjs(date).format('YYYY년 MM월 DD일');
};

const getEndDate = (startDate) => {
  if (!startDate) return '년     월     일';
  return dayjs(startDate).add(PC_VALID_DAYS, 'day').format('YYYY년 MM월 DD일');
};

const calculateImagePages = (images) => {
  // 페이지 당 이미지 수
  const IMAGES_PER_PAGE = 4;
  // 페이지 수 계산
  const totalImagePages = Math.ceil(images.length / IMAGES_PER_PAGE);
  // 이미지를 페이지 단위로 나눔
  const newimagePages = [];
  for (let i = 0; i < totalImagePages; i++) {
    newimagePages.push(
      images.slice(i * IMAGES_PER_PAGE, (i + 1) * IMAGES_PER_PAGE)
    );
  }

  return newimagePages;
};

const CheckboxGroup = ({
  options,
  data,
  setCheckState,
  itemKey,
  color = false,
}) => {
  return (
    <div className="flex flex-row align-items-center justify-content-start gap-3">
      {options &&
        options.map((option, index) => (
          <div
            key={`chk_${itemKey}_${index}`}
            className="flex flex-row align-items-center justify-content-start gap-1"
          >
            <img
              className="cbi"
              alt={`checkbox for ${option.label}`}
              src={setCheckState(data, itemKey, option.value)}
            />
            <span
              style={{
                color:
                  color && _.get(data, itemKey) === 5 && option.value === 5
                    ? 'red'
                    : 'inherit',
              }}
            >
              {option.label}
            </span>
          </div>
        ))}
    </div>
  );
};

const TableRow = ({
  className = '',
  colSpan = 2,
  title,
  itemKey,
  options,
  data,
  setCheckState,
}) => (
  <>
    <td className={className} colSpan={colSpan}>
      {title}
    </td>
    <td>
      <CheckboxGroup
        itemKey={itemKey}
        options={options}
        data={data}
        setCheckState={setCheckState}
      />
    </td>
  </>
);

const PerformanceCheckReport = forwardRef(
  (
    {
      data,
      addData,
      workDetails,
      repairDetails,
      photos,
      hRecheck,
      payment,
      association,
      inspector,
      customerCompany,
      vehicleInformation,
    },
    ref
  ) => {
    // console.log('hRecheck', hRecheck);
    // console.log('ASSRNC_TY_SE_CODE', _.get(data, 'ASSRNC_TY_SE_CODE'));
    // console.log('ICNY_CODE', _.get(data, 'ICNY_CODE'));
    // const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
    // const insurancePolicyNumber =
    //   myInfoLoadable.contents.shopInfo?.insurancePolicyNumber;
    const [officialSealUrls, setOfficialSealUrls] = useState({});
    const [isAvailable, setIsAvailable] = useState({
      certificate: false,
      ew: false,
    });
    const [imagePages, setImagePages] = useState([]);
    const totalValues = {
      checkData: data,
      photos,
      paymentInformation: payment,
      association,
      inspector,
      vehicleInformation,
      // insurancePolicyNumber,
    };

    async function getEncodedImgUrl(imageUrl) {
      return process.env.REACT_APP_S3_BASE_URL + imageUrl;
    }

    async function getAssociationSealUrl(association) {
      const { associationId } = association;

      try {
        const response = await associationService.getDataMobile(associationId);
        const { officialSealURL } = response.data;

        if (officialSealURL) {
          const encodedUrl = await getEncodedImgUrl(officialSealURL);
          setOfficialSealUrls((ps) => ({ ...ps, association: encodedUrl }));
        }
      } catch (error) {
        console.error('Failed to load association official seal:', error);
      }
    }

    async function getUserSealUrl(inspector) {
      const { userId } = inspector;

      try {
        const response = await userService.getDataExternal(userId);
        const { officialSealURL } = response.data;

        if (officialSealURL) {
          const encodedUrl = await getEncodedImgUrl(officialSealURL);
          setOfficialSealUrls((ps) => ({ ...ps, inspector: encodedUrl }));
        }
      } catch (error) {
        console.error('Failed to load inspector official seal:', error);
      }
    }

    function importCertImage(insuranceCode, isSummary) {
      try {
        if (isSummary) {
          if (insuranceCode === '09') {
            return [
              {
                page1: require(`../../assets/images/insurance/SUMMARY_00${insuranceCode}_01.webp`),
              },
              true,
            ];
          } else {
            return [
              {
                page1: require(`../../assets/images/insurance/SUMMARY_00${insuranceCode}_01.webp`),
                page2: require(`../../assets/images/insurance/SUMMARY_00${insuranceCode}_02.webp`),
              },
              true,
            ];
          }
        } else {
          return [
            {
              page1: require(`../../assets/images/insurance/GUIDE_00${insuranceCode}_01.webp`),
              page2: require(`../../assets/images/insurance/GUIDE_00${insuranceCode}_02.webp`),
              page3: require(`../../assets/images/insurance/GUIDE_00${insuranceCode}_03.webp`),
              page4: require(`../../assets/images/insurance/GUIDE_00${insuranceCode}_04.webp`),
            },
            true,
          ];
        }
      } catch (error) {
        console.log(error);
        return [
          {
            front: null,
            rear: null,
          },
          false,
        ];
      }
    }

    function renderInsuranceCertificate(insuranceCode) {
      const [urls] = importCertImage(insuranceCode, false);
      const { page1, page2, page3, page4 } = urls;

      const pages = [
        { src: page1, pageNumber: 1 },
        { src: page2, pageNumber: 2 },
        { src: page3, pageNumber: 3 },
        { src: page4, pageNumber: 4 },
      ];

      return (
        <div className="paper_container">
          {pages.map(({ src, pageNumber }) => (
            <div
              className="paper relative"
              key={`insurance_certificate_page_${insuranceCode}_page${pageNumber}`}
            >
              <div className="watermark"></div>
              <img
                src={src}
                alt={`insurance_certificate_page${pageNumber}`}
                style={{
                  width: '100%',
                  height: 'calc(100% - 4px)',
                }}
              />
              {pageNumber === 4 && (
                <>
                  {officialSealUrls.association && (
                    <img
                      src={_.get(officialSealUrls, 'association')}
                      alt="association_officialSeal"
                      style={{
                        position: 'absolute',
                        width: '40px',
                        top: insuranceCode === '09' ? '24.5%' : '28%',
                        left: insuranceCode === '09' ? '82%' : '77%',
                      }}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      );
    }

    function renderSummaryInsuranceCertificate(insuranceCode) {
      const [urls] = importCertImage(insuranceCode, true);
      const datas = _.filter(CERTIFICATE_DATAS, (data) =>
        _.get(data, 'insuranceCompanyCode').includes(insuranceCode)
      );

      const pages = [urls.page1, urls.page2].filter(Boolean);

      return (
        <div className="paper_container">
          {pages.map((src, index) => (
            <div
              className="paper relative"
              key={`insurance_certificate_page_${insuranceCode}_page${
                index + 1
              }`}
            >
              <div className="watermark"></div>
              <img
                src={src}
                alt={`insurance_certificate_page${index + 1}`}
                style={{ width: '100%', height: 'calc(100% - 4px)' }}
              />
              {index === 0 &&
                datas.map((data) => {
                  const [top, left] = _.get(data.pos, insuranceCode);
                  return (
                    <span
                      key={`${data.objName}_${data.name}`}
                      className="font-bold"
                      style={{
                        position: 'absolute',
                        top,
                        left,
                        color: data.color || 'black',
                      }}
                    >
                      {data.getValue(totalValues)}
                    </span>
                  );
                })}
              {/* 도장 - 현대 */}
              {insuranceCode === '09' && index === 0 && (
                <>
                  {officialSealUrls.association && (
                    <img
                      src={_.get(officialSealUrls, 'association')}
                      alt="association_officialSeal"
                      style={{
                        position: 'absolute',
                        width: '40px',
                        top: '87%',
                        left: '85%',
                      }}
                    />
                  )}
                </>
              )}
              {/* 도장 - DB */}
              {insuranceCode === '13' && index === 1 && (
                <>
                  {officialSealUrls.association && (
                    <img
                      src={_.get(officialSealUrls, 'association')}
                      alt="association_officialSeal"
                      style={{
                        position: 'absolute',
                        width: '40px',
                        top: '13.5%',
                        left: '84%',
                      }}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      );
    }

    function importEwImage(insuranceCode) {
      const isEw = _.get(totalValues, 'paymentInformation.ewYN') === YN.YES;
      try {
        return [
          {
            front: require(`../../assets/images/ew/EW_00${insuranceCode}_01.png`),
            rear: require(`../../assets/images/ew/EW_00${insuranceCode}_02.png`),
          },
          true && isEw,
        ];
      } catch (error) {
        console.log(error);
        return [
          {
            front: null,
            rear: null,
          },
          false,
        ];
      }
    }

    function renderEwInsuranceCertificate(insuranceCode) {
      // const isEw = _.get(totalValues, 'paymentInformation.ewYN') === YN.YES;

      // [09] 현대해상은 이미지가 아닌 직접 만든 보험 양식으로 대체하므로 바로 반환
      if (insuranceCode === '09') return;
      const { ewYN } = payment;
      console.log(ewYN);
      const [urls] = ewYN ? null : importEwImage(insuranceCode);
      const { front, rear } = urls;

      const datas = _.filter(EW_CERTIFICATE_DATAS, (data) => {
        return _.get(data, 'insuranceCompanyCode').indexOf(insuranceCode) >= 0;
      });

      return (
        <div className="paper_container">
          <div
            className="paper"
            key={`ew_insurance_certificate_page_${insuranceCode}_front`}
            // style={{
            //   width: '100%',
            //   height: '100%',
            // }}
          >
            <table className="w-full h-full">
              <thead />
              <tbody>
                <tr>
                  <td
                    colSpan={2}
                    className="content"
                    style={{
                      position: 'relative',
                      // width: '100%',
                      // height: '100%',
                    }}
                  >
                    <img
                      src={front}
                      alt="ew_insurance_certificate_front"
                      style={{
                        width: '100%',
                        height: 'calc(100% -4px)',
                      }}
                    />
                    {datas.map((data) => {
                      const [top, left] = _.get(data.pos, insuranceCode);
                      return (
                        <span
                          key={data.objName + data.name}
                          className={`${data.styleClass} font-semibold`}
                          style={{
                            position: 'absolute',
                            top: top,
                            left: left,
                            color: data.color || 'black',
                          }}
                        >
                          {data.getValue(totalValues)}
                        </span>
                      );
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="paper"
            key={`ew_insurance_certificate_page_${insuranceCode}_rear`}
          >
            <table className="w-full h-full">
              <thead />
              <tbody>
                <tr>
                  <td
                    colSpan={2}
                    className="content"
                    style={{ position: 'relative' }}
                  >
                    <img
                      src={rear}
                      alt="ew_insurance_certificate_front"
                      style={{
                        width: '100%',
                        height: 'calc(100% -4px)',
                      }}
                    />
                    {datas
                      .filter((data) => data.fieldName === 'enterpriseName')
                      .map((data) => {
                        const [top, left] = _.get(data.pos, insuranceCode);
                        return (
                          <span
                            key={data.objName + data.name}
                            className={`${data.styleClass} font-semibold`}
                            style={{
                              position: 'absolute',
                              top: top,
                              left: left,
                              color: data.color || 'black',
                            }}
                          >
                            {data.getValue(totalValues)}
                          </span>
                        );
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    useEffect(() => {
      (async () => {
        if (association !== null) {
          await getAssociationSealUrl(association);
        }

        if (inspector !== null) {
          await getUserSealUrl(inspector);
        }
      })();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [association, inspector]);

    useEffect(() => {
      if (payment) {
        const { ICNY_CODE, ewYN } = payment;
        const [certificateUrl, isCertificateAvailable] =
          importCertImage(ICNY_CODE);
        const isEw = ewYN === 'Y';
        const [ewUrl, isEwAvailable] = isEw ? null : importEwImage(ICNY_CODE);

        setIsAvailable({
          certificate: isCertificateAvailable,
          ew: isEwAvailable,
        });
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payment]);

    useEffect(() => {
      (async () => {
        if (photos && photos.length > 0) {
          // 한독은 모든 이미지 보이게 한다고 함
          async function getFormattedImages(images) {
            return await Promise.all(
              images
                .sort((a, b) => {
                  // 정면, 후면을 앞으로 배치
                  const partOrder = ['OUT_FRONT', 'OUT_REAR'];
                  const aIndex = partOrder.indexOf(a.partCode);
                  const bIndex = partOrder.indexOf(b.partCode);
                  if (aIndex === -1 && bIndex === -1) return 0; // 둘 다 순위에 없으면 그대로
                  if (aIndex === -1) return 1; // a가 순위에 없으면 b를 뒤로
                  if (bIndex === -1) return -1; // b가 순위에 없으면 a를 앞으로
                  return aIndex - bIndex; // 순서대로 배치
                })
                .map(async (img) => {
                  const encodedUrl = await getEncodedImgUrl(
                    _.get(img, 'filePath')
                  );
                  const alt = _.get(img, 'partCode');

                  img.src = encodedUrl;
                  img.alt = alt || '기타';

                  return img;
                })
            );
          }

          const formattedImages = await getFormattedImages(photos);
          const calculatedImagePages = calculateImagePages(formattedImages);
          setImagePages(calculatedImagePages);
        }
      })();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photos]);

    return (
      <div ref={ref} id="performance-check-report" className="pcr">
        {/* 페이지 - 표지 */}
        <div className="paper_container">
          <div className="paper bg-black-alpha-90 p-5 relative overflow-hidden text-white flex flex-column justify-content-between">
            <div>
              <header className="w-full flex justify-content-between align-contents-center px-3 pt-5 pb-8 mb-8">
                <div className="flex gap-2">
                  <p className="text-3xl font-semibold">HANDOK Inspection</p>
                </div>
                <p className="text-2xl">한독성능장</p>
              </header>
              <div className="w-full text-center px-3 py-5 border-top-1 border-bottom-1 border-white">
                <h1 className="text-6xl mb-0 line-height-2">
                  HD 프리미엄 중고차 성능 상태 기록부
                </h1>
              </div>
            </div>
            <div className="main_img">
              <div className="watermark2" />
            </div>
          </div>
        </div>

        {/* 페이지 - 보험요약약관 (직거래이고 보증일때만 표시) */}
        {hRecheck === 'N' &&
          _.get(data, 'ASSRNC_TY_SE_CODE') === '2' &&
          _.get(data, 'ICNY_CODE') &&
          payment &&
          _.get(isAvailable, 'certificate') &&
          renderSummaryInsuranceCertificate(_.get(payment, 'ICNY_CODE'))}

        {/* 페이지 - 자동차 기본 정보 & 상세 정보  */}
        <div className="paper_container">
          <div className="paper flex flex-column">
            <div className="watermark"></div>
            <PerformanceCheckReportHeader />
            <div className="section_title_new flex justify-content-between">
              <h4>자동차 기본 정보</h4>
              <div className="flex gap-3">
                <span className="text-lg font-bold">
                  점검번호: {_.get(data, 'PRFOMNC_CHCK_NO')}
                </span>
                <span className="text-lg font-bold">
                  점검일: {formatDate(data?.CHCK_DE)}
                </span>
              </div>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="title">차명</td>
                  <td className="text-lg">{_.get(data, 'CNM')}</td>
                  <td className="title">차량번호</td>
                  <td className="text-lg">{_.get(data, 'VHRNO')}</td>
                </tr>
                <tr>
                  <td className="title">연식</td>
                  <td>{_.get(data, 'PRYE')}</td>
                  <td className="title">검사 유효기간</td>
                  <td>
                    {dayjs(_.get(data, 'INSPT_VALID_PD_BGNDE')).format(
                      'YYYY년 MM월 DD일'
                    )}{' '}
                    ~{' '}
                    {dayjs(_.get(data, 'INSPT_VALID_PD_ENDDE')).format(
                      'YYYY년 MM월 DD일'
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="title">최초등록일</td>
                  <td>
                    {dayjs(_.get(data, 'FRST_REGIST_DE')).format(
                      'YYYY년 MM월 DD일'
                    )}
                  </td>
                  <td rowSpan="2" className="title">
                    변속기종류
                  </td>
                  <td rowSpan="2">
                    <div className="flex flex-column">
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'GRBX_KND_CODE', 'A')}
                          />
                          자동
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'GRBX_KND_CODE', 'M')}
                          />
                          수동
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'GRBX_KND_CODE', 'S')}
                          />
                          세미오토
                        </div>
                      </div>
                      <div className="mt-2 flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'GRBX_KND_CODE', 'C')}
                          />
                          무단변속기
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'GRBX_KND_CODE', 'X')}
                          />
                          기타 (
                          {_.get(data, 'GRBX_KND_DTLS') ||
                            '\u00A0\u00A0\u00A0\u00A0'}
                          )
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="title">차대번호</td>
                  <td>
                    {_.get(data, 'VIN').length === 11
                      ? `${_.get(data, 'VIN')}${_.get(
                          vehicleInformation,
                          'vinRemain'
                        )}`
                      : `${_.get(data, 'VIN')}`}
                  </td>
                </tr>
                <tr>
                  <td className="title">사용연료</td>
                  <td colSpan="3">
                    <CheckboxGroup
                      itemKey="USE_FUEL_CODE"
                      options={baseValueItems['USE_FUEL_CODE']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>
                {/* {_.get(data, 'ICNY_CODE') && (
                  <tr>
                    <td className="title">보증유형</td>
                    <td
                      colSpan="3"
                      style={{
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}
                    >
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'ASSRNC_TY_SE_CODE', '2')}
                          />
                          보험사보증 (성능보증이 가능한 차량)
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'ASSRNC_TY_SE_CODE', '1')}
                          />
                          보험사비보증 (13만Km 이상)
                        </div>
                      </div>
                    </td>
                  </tr>
                )} */}
                <tr>
                  <td className="title">원동기형식</td>
                  <td colSpan="3">{_.get(data, 'MTRS_FOM')}</td>
                </tr>
              </tbody>
            </table>

            <div className="section_title_new mt-6 ">
              <h4>자동차 상세 정보</h4>
            </div>
            <table className="table">
              <colgroup>
                <col width="18%" />
                <col width="32%" />
                <col width="18%" />
                <col width="32%" />
              </colgroup>
              <tbody>
                <tr>
                  <td className="title">사용이력</td>
                  <td className="title text-center">상태</td>
                  <td className="title text-center" colSpan="2">
                    항목
                  </td>
                </tr>
                <tr>
                  <td className="title">
                    주행거리 상태
                    <br />
                    (1년 2만Km 기준)
                  </td>
                  <td>
                    <CheckboxGroup
                      itemKey="TRVL_DSTNC_STTUS_CODE"
                      options={baseValueItems['TRVL_DSTNC_STTUS_CODE']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                  <td className="title">
                    현재 주행거리
                    <br />
                    (검사시 계시판 기준)
                  </td>
                  <td>
                    <span className="font-bold">
                      {CommonUtil.Unit.format(_.get(data, 'TRVL_DSTNC'))}
                    </span>
                    &nbsp; KM
                  </td>
                </tr>
                <tr>
                  <td className="title">계기 상태</td>
                  <td colSpan="3">
                    <CheckboxGroup
                      itemKey="GAUGE_FNCT_AT"
                      options={baseValueItems['GAUGE_FNCT_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="title">차대번호 표기</td>
                  <td colSpan="3">
                    <CheckboxGroup
                      itemKey="SAMENSS_CNFIRM_CODE"
                      options={baseValueItems['SAMENSS_CNFIRM_CODE']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="title">배출가스</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(
                            data,
                            'CHK_CRMN_MESURE_VALUE',
                            'Y'
                          )}
                        />
                        일산화탄소
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(
                            data,
                            'CHK_HYDR_MESURE_VALUE',
                            'Y'
                          )}
                        />
                        탄화수소
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(
                            data,
                            'CHK_SMOKE_MESURE_VALUE',
                            'Y'
                          )}
                        />
                        매연
                      </div>
                    </div>
                  </td>
                  <td colSpan="2">
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <span>
                        {_.get(data, 'CRMN_MESURE_VALUE') === ''
                          ? '0.0'
                          : _.get(data, 'CRMN_MESURE_VALUE')}{' '}
                        %,
                      </span>
                      <span>
                        {_.get(data, 'HYDR_MESURE_VALUE') === ''
                          ? '0'
                          : _.get(data, 'HYDR_MESURE_VALUE')}{' '}
                        ppm,
                      </span>
                      <span>
                        {_.get(data, 'SMOKE_MESURE_VALUE') === ''
                          ? '0'
                          : _.get(data, 'SMOKE_MESURE_VALUE')}{' '}
                        %
                      </span>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="title">튜닝</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'UNLAW_STMD_AT', '0')}
                        />
                        없음
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'UNLAW_STMD_AT', ['1', '2'])}
                        />
                        있음
                      </div>
                    </div>
                  </td>
                  <td colSpan="2">
                    <CheckboxGroup
                      itemKey="UNLAW_STMD_AT"
                      options={baseValueItems['UNLAW_STMD_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="title">특별이력</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'FLUD_AT', '0')}
                        />
                        없음
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'FLUD_AT', ['1', '2'])}
                        />
                        있음
                      </div>
                    </div>
                  </td>
                  <td colSpan="2">
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'FLUD_AT', ['1'])}
                        />
                        침수
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'FLUD_AT', ['2'])}
                        />
                        화재
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'FLUD_AT', ['3'])}
                        />
                        전손
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="title">용도변경</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'PRPOS_CHANGE_SE_CODE', '0')}
                        />
                        없음
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 'PRPOS_CHANGE_SE_CODE', [
                            '1',
                            '2',
                            '3',
                          ])}
                        />
                        있음
                      </div>
                    </div>
                  </td>
                  <td colSpan="2">
                    <CheckboxGroup
                      itemKey="PRPOS_CHANGE_SE_CODE"
                      options={baseValueItems['PRPOS_CHANGE_SE_CODE']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>
                {/* TODO 차량 색상, 주요옵션 */}
                {/* <tr>
                    <td className="title">색상</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'COLOR_AT', '1')}
                          />
                          무채색
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'COLOR_AT', '2')}
                          />
                          유채색
                        </div>
                      </div>
                    </td>
                    <td colSpan="2">
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'COLOR_CHANGE_AT', '1')}
                          />
                          전체도색
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'COLOR_CHANGE_AT', '2')}
                          />
                          색상변경
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">주요옵션</td>
                    <td>
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'TODO', '1')}
                          />
                          없음
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'TODO', '1')}
                          />
                          있음
                        </div>
                      </div>
                    </td>
                    <td colSpan="2">
                      <div className="flex flex-row align-items-center justify-content-start gap-3">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(
                              data,
                              'MAIN_OPTN_VHCLE_RF_AT',
                              '1'
                            )}
                          />
                          썬루프
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(
                              data,
                              'MAIN_OPTN_ROAD_GUIDANCE_AT',
                              '1'
                            )}
                          />
                          네비게이션
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setCheckState(data, 'MAIN_OPTN_ETC_AT', '1')}
                          />
                          기타
                        </div>
                      </div>
                    </td>
                  </tr> */}
                <tr>
                  <td className="title">리콜대상</td>
                  <td>
                    <CheckboxGroup
                      itemKey="RECALL_TRGET_AT"
                      options={baseValueItems['RECALL_TRGET_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                  <td className="title">리콜이행</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={
                            _.get(data, 'RECALL_TRGET_AT') === '1'
                              ? setCheckState(data, 'RECALL_FLFL_AT', '1')
                              : Unchecked
                          }
                        />
                        이행
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={
                            _.get(data, 'RECALL_TRGET_AT') === '1'
                              ? setCheckState(data, 'RECALL_FLFL_AT', '0')
                              : Unchecked
                          }
                        />
                        미이행
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* 페이지 - 차량 사고 교환 수리 이력 */}
        <div className="paper_container">
          <div className="paper flex flex-column">
            <div className="watermark"></div>
            <PerformanceCheckReportHeader />
            <div className="section_title_new mt-6">
              <h4>차량 사고 교환 수리 이력</h4>
            </div>
            <table className="table">
              <colgroup>
                <col width="18%" />
                <col width="32%" />
                <col width="18%" />
                <col width="32%" />
              </colgroup>
              <tbody>
                <tr>
                  <td className="bg-gray-100" colSpan="4">
                    <div className="flex flex-row align-items-center justify-content-start">
                      <div className="mr-2">※ 상태표시 부호 :</div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img src={IconStatus1} alt="교환" />
                          교환
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img src={IconStatus2} alt="판금 또는 용접" />
                          판금 또는 용접
                        </div>
                      </div>
                    </div>
                    ※ 하단 항목은 승용차 기준이며, 기타 자동차는 승용차에 준하여
                    표시
                  </td>
                </tr>
                <tr>
                  <td colSpan="11" className=" p-0">
                    <div className="flex flex-row align-items-center justify-content-evenly relative">
                      <div className="relative">
                        <img
                          src={BPLeft}
                          style={{
                            minHeight: '70mm',
                            height: '70mm',
                            maxHeight: '70mm',
                          }}
                          className="m-0 p-0 opacity-70"
                          alt={'차량이미지'}
                        />
                        {_.map(
                          PARTS_LEFT,
                          ({ partPoint, rpos: [top, left] }) => (
                            <div key={`key_${partPoint}`}>
                              <div className="absolute top-0 left-0 w-full h-full z-20">
                                <SvgComponent
                                  parts={PARTS_LEFT}
                                  partName="PARTS_LEFT"
                                  partPoint={partPoint}
                                  partState={_.get(data, partPoint)}
                                />
                              </div>
                              <div
                                className="cerp-pp absolute text-center text-lg font-bold"
                                style={{ top, left }}
                              >
                                {setPartState(_.get(data, partPoint))}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      <div className="relative">
                        <img
                          src={BPTop}
                          style={{
                            minHeight: '70mm',
                            height: '70mm',
                            maxHeight: '70mm',
                          }}
                          className="m-0 p-0 opacity-70"
                          alt={'차량이미지'}
                        />
                        {_.map(
                          PARTS_TOP,
                          ({ partPoint, rpos: [top, left] }) => {
                            return (
                              <div key={`key_${partPoint}`}>
                                <div className="absolute top-0 left-0 w-full h-full z-20">
                                  <SvgComponent
                                    parts={PARTS_TOP}
                                    partName="PARTS_TOP"
                                    partPoint={partPoint}
                                    partState={_.get(data, partPoint)}
                                  />
                                </div>
                                <div
                                  className="cerp-pp absolute text-center text-xl font-bold"
                                  style={{ top, left }}
                                >
                                  {setPartState(_.get(data, partPoint))}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                      <div className="relative">
                        <img
                          src={BPBottom}
                          style={{
                            minHeight: '70mm',
                            height: '70mm',
                            maxHeight: '70mm',
                          }}
                          className="m-0 p-0 opacity-70"
                          alt={'차량이미지'}
                        />
                        {_.map(
                          PARTS_BOTTOM,
                          ({ partPoint, rpos: [top, left] }) => (
                            <div key={`key_${partPoint}`}>
                              <div className="absolute top-0 left-0 w-full h-full z-20">
                                <SvgComponent
                                  parts={PARTS_BOTTOM}
                                  partName="PARTS_BOTTOM"
                                  partPoint={partPoint}
                                  partState={_.get(data, partPoint)}
                                />
                              </div>
                              <div
                                className="cerp-pp absolute text-center text-xl font-bold"
                                style={{ top, left }}
                              >
                                {setPartState(_.get(data, partPoint))}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      <div className="relative">
                        <img
                          src={BPRight}
                          style={{
                            minHeight: '70mm',
                            height: '70mm',
                            maxHeight: '70mm',
                          }}
                          className="m-0 p-0 opacity-70"
                          alt={'차량이미지'}
                        />
                        {_.map(
                          PARTS_RIGHT,
                          ({ partPoint, rpos: [top, left] }) => (
                            <div key={`key_${partPoint}`}>
                              <div className="absolute top-0 left-0 w-full h-full z-20">
                                <SvgComponent
                                  parts={PARTS_RIGHT}
                                  partName="PARTS_RIGHT"
                                  partPoint={partPoint}
                                  partState={_.get(data, partPoint)}
                                />
                              </div>
                              <div
                                className="cerp-pp absolute text-center text-xl font-bold"
                                style={{ top, left }}
                              >
                                {setPartState(_.get(data, partPoint))}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="title">
                    사고이력
                    <p className="text-xs">
                      (차량의 중요부위 사고가 있는 경우)
                    </p>
                  </td>
                  <td>
                    <CheckboxGroup
                      itemKey="ACDNT_AT"
                      options={baseValueItems['ACDNT_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                  <td className="title">
                    단순수리
                    <p className="text-xs">
                      (차량의 단순뷔위 교환, 판금 있는 경우)
                    </p>
                  </td>
                  <td>
                    <CheckboxGroup
                      itemKey="SIMPL_REPAIR_AT"
                      options={baseValueItems['SIMPL_REPAIR_AT']}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="section_title_gray_new mt-6">
              <h5>교환, 판금 등 이상 부위 상세 (위 차량 부위 참조)</h5>
            </div>
            <table className="table">
              <colgroup>
                <col width="18%" />
                <col width="20.5%" />
                <col width="61.5%" />
              </colgroup>
              <tbody>
                <tr>
                  <td className="title" rowSpan="2">
                    외판부위
                  </td>
                  <td className="text-center">1랭크</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's037', ['2', '3', '4'])}
                        />
                        1. 후드
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's038', ['2', '3', '4'])}
                        />
                        2. 프론트휀더
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's039', ['2', '3', '4'])}
                        />
                        3. 도어
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's040', ['2', '3', '4'])}
                        />
                        4. 트렁크 리드
                      </div>
                    </div>
                    <div className="mt-2 flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's041', ['2', '3', '4'])}
                        />
                        5. 라디에이터서포트(볼트체결부품)
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">2랭크</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's043', ['2', '3', '4'])}
                        />
                        6. 쿼터패널(리어휀더)
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's042', ['2', '3', '4'])}
                        />
                        7. 루프패널
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's044', ['2', '3', '4'])}
                        />
                        8. 사이드실패널
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td rowSpan="3" className="title">
                    주요골격
                  </td>
                  <td className="text-center">A랭크</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's045', ['2', '3', '4'])}
                        />
                        9. 프론트패널
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's046', ['2', '3', '4'])}
                        />
                        10. 크로스멤버
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's047', ['2', '3', '4'])}
                        />
                        11. 인사이드패널
                      </div>
                    </div>
                    <div className="mt-2 flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's054', ['2', '3', '4'])}
                        />
                        17. 트렁크플로어
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's053', ['2', '3', '4'])}
                        />
                        18. 리어패널
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">B랭크</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's048', ['2', '3', '4'])}
                        />
                        12. 사이드멤버
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's049', ['2', '3', '4'])}
                        />
                        13. 휠하우스
                      </div>
                    </div>
                    <div className="mt-2 flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's052', ['2', '3', '4'])}
                        />
                        14. 필러패널
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-2">
                        <div>(</div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setSubCheckState(
                              data,
                              ['pp_63', 'pp_78'],
                              ['2', '3', '4']
                            )}
                          />
                          A,
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setSubCheckState(
                              data,
                              ['pp_103', 'pp_118'],
                              ['2', '3', '4']
                            )}
                          />
                          B,
                        </div>
                        <div className="flex flex-row align-items-center justify-content-start gap-1">
                          <img
                            className="cbi"
                            alt="checkbox"
                            src={setSubCheckState(
                              data,
                              ['pp_144', 'pp_137'],
                              ['2', '3', '4']
                            )}
                          />
                          C
                        </div>
                        <div>)</div>
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's056', ['2', '3', '4'])}
                        />
                        19. 패키지트레이
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">C랭크</td>
                  <td>
                    <div className="flex flex-row align-items-center justify-content-start gap-3">
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's050', ['2', '3', '4'])}
                        />
                        15. 대쉬패널
                      </div>
                      <div className="flex flex-row align-items-center justify-content-start gap-1">
                        <img
                          className="cbi"
                          alt="checkbox"
                          src={setCheckState(data, 's051', ['2', '3', '4'])}
                        />
                        16. 플로어패널
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* 페이지 - 차량 주요장치 누유 및 불량 상세 1 */}
        <div className="paper_container">
          <div className="paper flex flex-column">
            <div className="watermark"></div>
            <PerformanceCheckReportHeader />
            <div className="section_title_gray_new mt-4">
              <h5>차량 주요장치 누유 및 불량 상세</h5>
            </div>
            <table className="table">
              <colgroup>
                <col width="18%" />
                <col width="20.5%" />
                <col width="20.5%" />
                <col width="41%" />
              </colgroup>
              <tbody>
                <tr>
                  <td className="title">주요장치</td>
                  <td colSpan="2" className="title text-center">
                    항목 / 해당부품
                  </td>
                  <td className="title text-center">상태</td>
                </tr>
                <tr>
                  <td rowSpan="2" className="title">
                    자기진단
                  </td>
                  <TableRow
                    colSpan="2"
                    title={baseTitles['ENGINE_STBLT_AT']}
                    itemKey="ENGINE_STBLT_AT"
                    options={baseValueItems['ENGINE_STBLT_AT']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <TableRow
                    colSpan="2"
                    title={baseTitles['GRBX_STBLT_AT']}
                    itemKey="GRBX_STBLT_AT"
                    options={baseValueItems['GRBX_STBLT_AT']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <td className="title" rowSpan="10">
                    원동기 (엔진)
                  </td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s001']}
                    itemKey="s001"
                    options={statusValueItems['s001']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <td rowSpan="3">오일누유</td>
                  <TableRow
                    colSpan="1"
                    title={statusTitles['s057']}
                    itemKey="s057"
                    options={statusValueItems['s057']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s003', 's058'].map((item, idx) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="1"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s005']}
                    itemKey="s005"
                    options={statusValueItems['s005']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <td rowSpan="4">냉각수 누수</td>
                  <TableRow
                    colSpan="1"
                    title={statusTitles['s007']}
                    itemKey="s007"
                    options={statusValueItems['s007']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s009', 's008', 's010'].map((item, idx) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="1"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s011']}
                    itemKey="s011"
                    options={statusValueItems['s011']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <td rowSpan="7" className="title">
                    변속기 (미션)
                  </td>
                  <td rowSpan="3">자동변속기 (A/T)</td>
                  <TableRow
                    colSpan="1"
                    title={statusTitles['s012']}
                    itemKey="s012"
                    options={statusValueItems['s012']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s013', 's016'].map((item) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="1"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <td rowSpan="4">수동변속기 (M/T)</td>
                  <TableRow
                    colSpan="1"
                    title={statusTitles['s018']}
                    itemKey="s018"
                    options={statusValueItems['s018']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s017', 's019', 's020'].map((item, idx) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="1"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* 페이지 - 차량 주요장치 누유 및 불량 상세 2 */}
        <div className="paper_container">
          <div className="paper flex flex-column">
            <div className="watermark"></div>
            <PerformanceCheckReportHeader mini={true} />
            <table className="table table_narrow">
              <colgroup>
                <col width="18%" />
                <col width="20.5%" />
                <col width="20.5%" />
                <col width="41%" />
              </colgroup>
              <tbody>
                <tr>
                  <td rowSpan="4" className="title">
                    동력전달
                  </td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s021']}
                    itemKey="s021"
                    options={statusValueItems['s021']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s022', 's023', 's060'].map((item, idx) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="2"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <td rowSpan="6" className="title">
                    조향
                  </td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s024']}
                    itemKey="s024"
                    options={statusValueItems['s024']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <td rowSpan="5">작동상태</td>
                  <TableRow
                    colSpan="1"
                    title={statusTitles['s026']}
                    itemKey="s026"
                    options={statusValueItems['s026']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s025', 's061', 's062', 's027'].map((item) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="1"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <td rowSpan="3" className="title">
                    제동
                  </td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s059']}
                    itemKey="s059"
                    options={statusValueItems['s059']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s029', 's030'].map((item) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="2"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <td rowSpan="6" className="title">
                    전기
                  </td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s031']}
                    itemKey="s031"
                    options={statusValueItems['s031']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s055', 's032', 's033', 's034', 's036'].map((item) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="2"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <td rowSpan="3" className="title">
                    고전원 전기장치(전기차)
                  </td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s063']}
                    itemKey={'s063'}
                    options={statusValueItems['s063']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                {['s064', 's065'].map((item) => (
                  <tr key={`tr_${item}`}>
                    <TableRow
                      colSpan="2"
                      title={statusTitles[item]}
                      itemKey={item}
                      options={statusValueItems[item]}
                      data={data}
                      setCheckState={setCheckState}
                    />
                  </tr>
                ))}
                <tr>
                  <td className="title">연료</td>
                  <TableRow
                    colSpan="2"
                    title={statusTitles['s035']}
                    itemKey={'s035'}
                    options={statusValueItems['s035']}
                    data={data}
                    setCheckState={setCheckState}
                  />
                </tr>
                <tr>
                  <td className="title">특이 사항 및 점검자 의견</td>
                  <td colSpan="3">{_.get(data, 'ETC_MATTER')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* 페이지 - 상품화 (직거래일 때만 표시) */}
        {hRecheck === 'N' && (
          <div className="paper_container">
            <div className="paper flex flex-column">
              <div className="watermark"></div>
              <PerformanceCheckReportHeader mini={true} />
              <div className="section_title_new">
                <h4>상품화</h4>
              </div>
              <table className="table">
                <colgroup>
                  <col width="20%" />
                  <col width="20%" />
                </colgroup>
                <tbody>
                  {/* 상태표시 부호 */}
                  <tr>
                    <td className="bg-gray-100" colSpan="2">
                      <div className="flex align-items-center justify-content-start">
                        <div className="mr-2">※ 상태표시 부호 :</div>
                        <div className="flex align-items-center justify-content-start gap-5">
                          {['외장', '유리', '내장'].map((category, idx) => (
                            <div className="flex gap-2" key={idx}>
                              {ADD_PARTS_STATUS.filter(
                                (item) => item.category === category
                              ).map((item, index) => (
                                <div
                                  className="flex flex-auto align-items-center gap-1"
                                  key={index}
                                >
                                  {index === 0 && (
                                    <span className="mr-1">
                                      &#91;{item.category}&#93;
                                    </span>
                                  )}
                                  <Badge
                                    value={item.status_code}
                                    severity={item.severity}
                                  />
                                  <span>{item.status_name}</span>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      {/* 이미지 */}
                      <div className="flex justify-content-center gap-5">
                        <div
                          className="relative"
                          style={{ width: '200px', marginBottom: '10px' }}
                        >
                          <Image
                            src={BPLeft}
                            alt="차량 왼쪽 이미지"
                            height="250"
                          />
                          {/* ADD_PARTS_LEFT 목록을 순회하여 각 파트 위치에 색상 오버레이 컴포넌트를 렌더링 */}
                          {/* ADD_PARTS_LEFT 목록을 순회하여 값이 빈 문자열이 아닌 파트만 렌더링 */}
                          {ADD_PARTS_LEFT.filter(
                            ({ partPoint }) =>
                              addData.addPartStatus?.[partPoint] !== ''
                          ).map(
                            ({ partPoint, title, reportPos: [top, left] }) => (
                              <div key={partPoint}>
                                <div
                                  className="absolute"
                                  style={{ top: 7, left: 22 }}
                                >
                                  <SvgComponent2
                                    parts={ADD_PARTS_LEFT}
                                    partName="ADD_PARTS_LEFT"
                                    partPoint={partPoint}
                                    partState={
                                      addData.addPartStatus?.[partPoint]
                                    }
                                  />
                                </div>
                                <p
                                  style={{
                                    position: 'absolute',
                                    top,
                                    left,
                                    zIndex: 2,
                                    fontSize: 10,
                                    whiteSpace: 'nowrap',
                                    background: 'rgba(255, 255, 255, 0.9)',
                                  }}
                                >
                                  {title}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                        <div className="relative" style={{ width: '200px' }}>
                          <Image
                            src={BPRight}
                            alt="차량 오른쪽 이미지"
                            height="250"
                          />
                          {ADD_PARTS_RIGHT.filter(
                            ({ partPoint }) =>
                              addData.addPartStatus?.[partPoint] !== ''
                          ).map(
                            ({ partPoint, title, reportPos: [top, left] }) => (
                              <div key={partPoint}>
                                <div
                                  className="absolute"
                                  style={{ top: 8, left: 17 }}
                                >
                                  <SvgComponent2
                                    parts={ADD_PARTS_RIGHT}
                                    partName="ADD_PARTS_RIGHT"
                                    partPoint={partPoint}
                                    partState={
                                      addData.addPartStatus?.[partPoint]
                                    }
                                  />
                                </div>
                                <p
                                  style={{
                                    position: 'absolute',
                                    top,
                                    left,
                                    zIndex: 2,
                                    fontSize: 10,
                                    whiteSpace: 'nowrap',
                                    background: 'rgba(255, 255, 255, 0.9)',
                                  }}
                                >
                                  {title}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="flex justify-content-center gap-5">
                        <div className="relative" style={{ width: '200px' }}>
                          <Image
                            src={BPTop}
                            alt="차량 위쪽 이미지"
                            height="250"
                          />
                          {ADD_PARTS_TOP.filter(
                            ({ partPoint }) =>
                              addData.addPartStatus?.[partPoint] !== ''
                          ).map(
                            ({ partPoint, title, reportPos: [top, left] }) => (
                              <div key={partPoint}>
                                <div
                                  className="absolute"
                                  style={{ top: 10, left: 30 }}
                                >
                                  <SvgComponent2
                                    parts={ADD_PARTS_TOP}
                                    partName="ADD_PARTS_TOP"
                                    partPoint={partPoint}
                                    partState={
                                      addData.addPartStatus?.[partPoint]
                                    }
                                  />
                                </div>
                                <p
                                  style={{
                                    position: 'absolute',
                                    top,
                                    left,
                                    zIndex: 2,
                                    fontSize: 10,
                                    whiteSpace: 'nowrap',
                                    background: 'rgba(255, 255, 255, 0.9)',
                                  }}
                                >
                                  {title}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                        {/* 차량 내부 이미지 */}
                        <div
                          className="relative"
                          style={{ width: '200px', marginLeft: '50px' }}
                        >
                          <Image
                            src={BPInner}
                            alt="차량 내부 이미지"
                            height="240"
                            className="mt-2"
                          />
                          {ADD_PARTS_INNER.filter(
                            ({ partPoint }) =>
                              addData.addPartStatus?.[partPoint] !== ''
                          ).map(
                            ({ partPoint, title, reportPos: [top, left] }) => (
                              <div key={partPoint}>
                                <div
                                  className="absolute"
                                  style={{ top: 27, left: 2 }}
                                >
                                  <SvgComponent2
                                    parts={ADD_PARTS_INNER}
                                    partName="ADD_PARTS_INNER"
                                    partPoint={partPoint}
                                    partState={
                                      addData.addPartStatus?.[partPoint]
                                    }
                                  />
                                </div>
                                <p
                                  style={{
                                    position: 'absolute',
                                    top,
                                    left,
                                    zIndex: 2,
                                    fontSize: 10,
                                    whiteSpace: 'nowrap',
                                    background: 'rgba(255, 255, 255, 0.9)',
                                  }}
                                >
                                  {title}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">휠</td>
                    <td>
                      <div className="flex align-items-center gap-3">
                        <div className="flex align-items-center gap-1 w-6">
                          <span>운전석 앞</span>
                          <span className="pl-6">
                            <CheckboxGroup
                              itemKey="carWheelFL"
                              options={addValueItems['carWheelFL']}
                              data={addData}
                              setCheckState={setCheckState}
                              color={true}
                            />
                          </span>
                        </div>
                        <div className="flex align-items-center gap-1 w-6">
                          <span>조수석 앞</span>
                          <span className="pl-6">
                            <CheckboxGroup
                              itemKey="carWheelFR"
                              options={addValueItems['carWheelFR']}
                              data={addData}
                              setCheckState={setCheckState}
                              color={true}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="mt-2 flex align-items-center gap-3">
                        <div className="flex align-items-center gap-1 w-6">
                          <span>운전석 뒤</span>
                          <span className="pl-6">
                            <CheckboxGroup
                              itemKey="carWheelRL"
                              options={addValueItems['carWheelRL']}
                              data={addData}
                              setCheckState={setCheckState}
                              color={true}
                            />
                          </span>
                        </div>
                        <div className="flex align-items-center gap-1 w-6">
                          <span>조수석 뒤</span>
                          <span className="pl-6">
                            <CheckboxGroup
                              itemKey="carWheelRR"
                              options={addValueItems['carWheelRR']}
                              data={addData}
                              setCheckState={setCheckState}
                              color={true}
                            />
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">
                      타이어 트레드
                      <br />
                      ※1.6mm 미만 시 불량
                    </td>
                    <td>
                      <div className="flex align-items-center gap-3">
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'tiretreadFL') < 1.6 &&
                            _.get(addData, 'tiretreadFL') > 0
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          운전석 앞
                          <span className="pl-6">
                            {_.get(addData, 'tiretreadFL') === 0
                              ? '미입력'
                              : `${_.get(addData, 'tiretreadFL')} mm`}
                          </span>
                        </div>
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'tiretreadFR') < 1.6 &&
                            _.get(addData, 'tiretreadFR') > 0
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          조수석 앞
                          <span className="pl-6">
                            {_.get(addData, 'tiretreadFR') === 0
                              ? '미입력'
                              : `${_.get(addData, 'tiretreadFR')} mm`}
                          </span>
                        </div>
                      </div>
                      <div className="mt-2 flex align-items-center gap-3">
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'tiretreadRL') < 1.6 &&
                            _.get(addData, 'tiretreadRL') > 0
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          운전석 뒤
                          <span className="pl-6">
                            {_.get(addData, 'tiretreadRL') === 0
                              ? '미입력'
                              : `${_.get(addData, 'tiretreadRL')} mm`}
                          </span>
                        </div>
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'tiretreadRR') < 1.6 &&
                            _.get(addData, 'tiretreadRR') > 0
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          조수석 뒤
                          <span className="pl-6">
                            {_.get(addData, 'tiretreadRR') === 0
                              ? '미입력'
                              : `${_.get(addData, 'tiretreadRR')} mm`}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">
                      브레이크 패드
                      <br />
                      ※30% 미만 시 불량
                    </td>
                    <td>
                      <div className="flex align-items-center gap-3">
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'brakePadFL') < 30 &&
                            _.get(addData, 'brakePadFL') > 0
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          운전석 앞
                          <span className="pl-6">
                            {_.get(addData, 'brakePadFL') === 0
                              ? '미입력'
                              : `${_.get(addData, 'brakePadFL')} %`}
                          </span>
                        </div>
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'brakePadFR') < 30 &&
                            _.get(addData, 'brakePadFR') > 0
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          조수석 앞
                          <span className="pl-6">
                            {_.get(addData, 'brakePadFR') === 0
                              ? '미입력'
                              : `${_.get(addData, 'brakePadFR')} %`}
                          </span>
                        </div>
                      </div>
                      <div className="mt-2 flex align-items-center gap-3">
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'brakePadRL') < 30 &&
                            _.get(addData, 'brakePadRL') > 0
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          운전석 뒤
                          <span className="pl-6">
                            {_.get(addData, 'brakePadRL') === 0
                              ? '미입력'
                              : `${_.get(addData, 'brakePadRL')} %`}
                          </span>
                        </div>
                        <div
                          className="flex align-items-center gap-1 w-6"
                          style={
                            _.get(addData, 'brakePadRR') < 30 &&
                            _.get(addData, 'brakePadRR') > 0
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          조수석 뒤
                          <span className="pl-6">
                            {_.get(addData, 'brakePadRR') === 0
                              ? '미입력'
                              : `${_.get(addData, 'brakePadRR')} %`}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">내장</td>
                    <td>{_.get(addData, 'carInteriorNote')}</td>
                  </tr>
                  <tr>
                    <td className="title">룸크리닝</td>
                    <td>{_.get(addData, 'roomCleaningNote')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* 페이지 - 사진 */}
        {imagePages.map((imageGroup, pageIndex) => (
          <div key={`page_${pageIndex}`} className="paper_container">
            <div className="paper flex flex-column">
              <div className="watermark"></div>
              <PerformanceCheckReportHeader mini={true} />
              <div className="section_title_gray_new ">
                <h5>점검 장면 촬영 사진 (점검 부위 및 이상 부위 포함)</h5>
              </div>
              <div className="flex flex-wrap">
                {imageGroup.map((photo, idx) => {
                  if (!photo) return null;
                  const {
                    originalFileName,
                    src,
                    alt,
                    photoId,
                    partCode,
                    note,
                  } = photo;
                  return (
                    <div
                      key={`performance_check_img_${
                        originalFileName + idx + photoId
                      }`}
                      className="w-6 flex flex-column align-items-center"
                    >
                      <img
                        id={originalFileName + photoId}
                        src={`${src}?timestamp=${Date.now()}`}
                        crossOrigin="anonymous"
                        alt={alt}
                        style={{
                          width: '87%',
                          aspectRatio: '3/4',
                          objectFit: 'cover',
                          border: '1px solid lightgray',
                          zIndex: 1,
                        }}
                      />
                      <p className="font-bold text-lg p-2">
                        {PHOTO_TYPE[partCode] !== '' && note
                          ? `${PHOTO_TYPE[partCode]} - ${note}`
                          : PHOTO_TYPE[partCode] || note}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}

        {/* 페이지 - 종합이력 */}
        <PaginatedRepairHistory
          workDetails={workDetails}
          repairDetails={repairDetails}
        />

        {/* 페이지 - 보험약관 (직거래이고 보증일때만 표시) */}
        {hRecheck === 'N' &&
          _.get(data, 'ASSRNC_TY_SE_CODE') === '2' &&
          _.get(data, 'ICNY_CODE') &&
          payment &&
          _.get(isAvailable, 'certificate') &&
          renderInsuranceCertificate(_.get(payment, 'ICNY_CODE'))}

        {/* 페이지 - EW 보증 */}
        {/* {hRecheck === 'N' &&
          _.get(data, 'ASSRNC_TY_SE_CODE') === '2' &&
          _.get(data, 'ICNY_CODE') &&
          payment &&
          _.get(isAvailable, 'ew') &&
          renderEwInsuranceCertificate(_.get(payment, 'ICNY_CODE'))} */}
      </div>
    );
  }
);

export default PerformanceCheckReport;
