import React, { useEffect, useState } from 'react';
import { ADD_PARTS_STATUS } from '../../constants/PerformanceCheckConstants';

const SvgComponent2 = ({ parts, partPoint, partState, partName }) => {
  const [item, setItem] = useState(null);

  useEffect(() => {
    const foundItem = parts.find((part) => part.partPoint === partPoint);
    setItem(foundItem || null);
  }, [partPoint, parts]);

  function getStatusDetails(status_code) {
    const status = ADD_PARTS_STATUS.find(
      (item) => item.status_code === status_code
    );
    if (status) {
      return { color: status.color, status_name: status.status_name };
    } else {
      return { color: 'transparent', status_name: '' };
    }
  }

  function getViewBox(partName) {
    switch (partName) {
      case 'ADD_PARTS_LEFT':
        return '0 0 206 503';
      case 'ADD_PARTS_RIGHT':
        return '0 0 206 503';
      case 'ADD_PARTS_TOP':
        return '0 0 134 286';
      case 'ADD_PARTS_INNER':
        return '0 0 215 453';
      default:
        return '0 0 655 1200';
    }
  }

  function getHeight(partName) {
    switch (partName) {
      case 'ADD_PARTS_LEFT':
        return '235';
      case 'ADD_PARTS_RIGHT':
        return '235';
      case 'ADD_PARTS_TOP':
        return '225';
      case 'ADD_PARTS_INNER':
        return '210';
      default:
        return '200';
    }
  }
  return (
    <>
      {item && (
        <>
          <svg
            viewBox={getViewBox(partName)}
            height={getHeight(partName)}
            opacity={0.4}
          >
            <g
              fill={getStatusDetails(partState).color}
              dangerouslySetInnerHTML={{ __html: item.element }}
            />
          </svg>
          <p
            style={{
              position: 'absolute',
              top: item.pos[0],
              left: item.pos[1],
              zIndex: 99,
              // wordBreak: noB
            }}
          >
            {/* {item.title} */}
          </p>
        </>
      )}
    </>
  );
};

export default SvgComponent2;
