import { COUNT_PER_PAGE, YN } from '../../constants/Constants';
import React, { useEffect, useMemo, useState } from 'react';
import { columnDateTime, columnUseYn } from '../../utils/dataTableUtil';

import { CodeUtil } from '../../utils/CodeUtil';
import { Column } from 'primereact/column';
import { CommonUtil } from '../../utils/commonUtil';
import CustomerCompanyDialog from '../../components/Customer/CustomerCompanyDialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import SearchMenu from '../../components/Menu/SearchMenu';
import ServiceMenu from '../../components/Menu/ServiceMenu';
import { ServiceProvider } from '../../services';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import { myInfoSelector } from '../../recoil/selectors';
import { useAuthorizedFetch } from '../../hooks/useAuthorizedFetch';
import { useRecoilValueLoadable } from 'recoil';

const DEFAULT_SEARCH_CONDITIONS = {
  associations: [],
  enterprises: [],
  shops: [],
  searchText: '',
  useYN: 'Y',
};
const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
  sortField: null,
  sortOrder: null,
  filters: {
    name: { value: '', matchMode: 'contains' },
    'country.name': { value: '', matchMode: 'contains' },
    company: { value: '', matchMode: 'contains' },
    'representative.name': { value: '', matchMode: 'contains' },
  },
};
const customerCompanyService = ServiceProvider.customerCompany;

const CustomerCompanyList = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(
    () => MyInfoUtil.unpack(myInfoLoadable.contents),
    [myInfoLoadable.contents]
  );

  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });

  const { loading, totalRecords, data, getList, initList } = useAuthorizedFetch(
    customerCompanyService,
    searchConditions,
    setSearchConditions,
    DEFAULT_SEARCH_CONDITIONS,
    lazyParams.page,
    lazyParams.rows
  );
  const [currentCustomerCompanyId, setCurrentCustomerCompanyId] =
    useState(null);
  const [codes, setCodes] = useState({});

  function checkRegistAuth(userInfo) {
    const { roleCode } = userInfo;
    // not A_OFF, C~
    const ACCESS_PERMISSION = _.filter(UserRoleType, function (r) {
      return r.value === 'A_OFF' || _.startsWith(r.value, 'C_');
    });
    return !(_.findIndex(ACCESS_PERMISSION, { value: roleCode }) >= 0);
  }

  function handleSelectAll(
    selected,
    field,
    updateCodes,
    updateSearchConditions
  ) {
    // 전체 값을 선택한 게 있으면
    // 나머지 옵션에 disabled 처리를 해서 더이상 선택하지 못하도록 함
    // 전체 값 선택 안 했으면
    // disabled 리셋해서 codes 업데이트
    const update = [];

    if (selected && selected.includes('')) {
      update.push(
        ..._.get(codes, `${field}`).map((option) => {
          if (option.value !== '') {
            option.disabled = true;
          }
          return option;
        })
      );

      setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));
      setSearchConditions((ps) => ({
        ...ps,
        [field]: [_.get(codes[field], '0.value')],
        ...updateSearchConditions,
      }));
      return;
    }

    update.push(
      ..._.get(codes, `${field}`).map((option) => {
        if (option.disabled) delete option.disabled;
        return option;
      })
    );

    setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));

    if (selected === null) {
      setSearchConditions((ps) => ({ ...ps, [field]: '' }));
      return;
    }

    setSearchConditions((ps) => ({
      ...ps,
      [field]: selected,
      ...updateSearchConditions,
    }));
  }

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);

        const [associations, associationId, association] =
          await CodeUtil.getAssociationCodes(myInfo, {
            preSelectedAssociationId: myAssociationId,
          });
        const [enterprises, enterpriseId, enterprise] =
          await CodeUtil.getEnterpriseCodes(myInfo, {
            isParentEmpty: _.isEmpty(associations),
            associationId: myAssociationId,
          });
        const [shops, shopId, shop] = await CodeUtil.getShopCodes(myInfo, {
          isParentEmpty: _.isEmpty(enterprises),
          enterpriseId: myEnterpriseId,
        });

        setCodes((ps) => ({ ...ps, associations, enterprises, shops }));

        await getList({
          ...searchConditions,
          associations: [associationId],
          enterprises: [enterpriseId],
          shops: [shopId],
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.state]);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
              return (
                <>
                  <SearchMenu
                    onInit={() => initList()}
                    onSearch={() =>
                      getList(
                        searchConditions,
                        _.get(DEFAULT_LAZY_PARAMS, 'page'),
                        _.get(DEFAULT_LAZY_PARAMS, 'rows')
                      )
                    }
                  >
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속협회</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.associations}
                        display="chip"
                        placeholder="전체 협회"
                        value={searchConditions.associations}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [enterprises, enterpriseId, enterprise] =
                            await CodeUtil.getEnterpriseCodes(myInfo, {
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'associations')
                              ),
                              associationId: value,
                            });
                          const [shops, shopId, shop] =
                            await CodeUtil.getShopCodes(myInfo, {
                              isParentEmpty: _.isEmpty(enterprises),
                            });

                          handleSelectAll(
                            value,
                            'associations',
                            {
                              enterprises,
                              shops,
                            },
                            {
                              enterprises: [],
                              shops: [],
                            }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속점검법인</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.enterprises}
                        display="chip"
                        placeholder="전체 업체"
                        value={searchConditions.enterprises}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [shops, shopId, shop] =
                            await CodeUtil.getShopCodes(myInfo, {
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'enterprises')
                              ),
                              enterpriseId: value,
                            });

                          handleSelectAll(
                            value,
                            'enterprises',
                            {
                              shops,
                            },
                            {
                              shops: [],
                            }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속점검장</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.shops}
                        display="chip"
                        placeholder="전체 점검장"
                        value={searchConditions.shops}
                        filter
                        showSelectAll={false}
                        onChange={({ value }) => {
                          handleSelectAll(value, 'shops');
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">사용여부</label>
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_all"
                            name="useYN"
                            value=""
                            onChange={(e) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: e.value,
                              }));
                              getList(
                                {
                                  ...searchConditions,
                                  useYN: e.value,
                                },
                                DEFAULT_LAZY_PARAMS.page,
                                DEFAULT_LAZY_PARAMS.rows
                              );
                            }}
                            checked={searchConditions.useYN === ''}
                          />
                          <label htmlFor="radio_useYN_all">전체</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_y"
                            name="useYN"
                            value="Y"
                            onChange={(e) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: e.value,
                              }));
                              getList(
                                {
                                  ...searchConditions,
                                  useYN: e.value,
                                },
                                DEFAULT_LAZY_PARAMS.page,
                                DEFAULT_LAZY_PARAMS.rows
                              );
                            }}
                            checked={searchConditions.useYN === YN.YES}
                          />
                          <label htmlFor="radio_useYN_y">사용</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_n"
                            name="useYN"
                            value="N"
                            onChange={(e) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: e.value,
                              }));
                              getList(
                                {
                                  ...searchConditions,
                                  useYN: e.value,
                                },
                                DEFAULT_LAZY_PARAMS.page,
                                DEFAULT_LAZY_PARAMS.rows
                              );
                            }}
                            checked={searchConditions.useYN === YN.NO}
                          />
                          <label htmlFor="radio_useYN_n">미사용</label>
                        </div>
                      </div>
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">키워드</label>
                      <InputText
                        className="w-full"
                        value={searchConditions.searchText}
                        placeholder="매매상사 이름, 사업자등록번호로 검색"
                        onChange={({ target: { value } }) =>
                          //TODO: 대표전화 일때만 포매팅해서 보낼것
                          {
                            let v = value;
                            const numRegExp = /[0-9]/g;
                            if (
                              numRegExp.test(Number(value[0])) &&
                              value[0] === '0'
                            ) {
                              v = CommonUtil.Formatter.phone(value);
                            }
                            setSearchConditions((ps) => ({
                              ...ps,
                              searchText: v,
                            }));
                          }
                        }
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            getList(
                              searchConditions,
                              DEFAULT_LAZY_PARAMS.page,
                              DEFAULT_LAZY_PARAMS.rows
                            );
                          }
                        }}
                      />
                    </div>
                  </SearchMenu>
                  <ServiceMenu
                    title={'매매상사'}
                    onClickRegisterBtn={() => setCurrentCustomerCompanyId(0)}
                    excelData={{
                      type: 'customerCompany',
                      params: {
                        ...searchConditions,
                        page: lazyParams.page + 1,
                        size: lazyParams.rows,
                      },
                    }}
                    buttonVisible={checkRegistAuth(myInfoLoadable.contents)}
                  />

                  <Panel className="shadow-1 mb-3">
                    <DataTable
                      loading={loading}
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={totalRecords}
                      onPage={async (event) => {
                        setLazyParams(event);
                        console.log(event);
                        await getList(searchConditions, event.page, event.rows);
                      }}
                      paginator
                      responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      emptyMessage="데이터가 없습니다."
                    >
                      <Column
                        header="순번"
                        align={'center'}
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        field="companyName"
                        header="매매상사명"
                        body={({ customerCompanyId, companyName }) => (
                          <a
                            href="#"
                            onClick={() =>
                              setCurrentCustomerCompanyId(customerCompanyId)
                            }
                          >
                            {companyName}
                          </a>
                        )}
                      />
                      <Column
                        field="businessRegistrationName"
                        header={
                          <div>
                            <span>사업자명</span>
                            <br />
                            <span>사업자등록번호</span>
                          </div>
                        }
                        body={({
                          businessRegistrationName,
                          businessRegistrationNumber,
                        }) => (
                          <div>
                            <span>{businessRegistrationName}</span>
                            <br />
                            <span>
                              {businessRegistrationNumber &&
                                CommonUtil.Formatter.biz(
                                  businessRegistrationNumber
                                )}
                            </span>
                          </div>
                        )}
                      />
                      <Column
                        field="businessRegistrationName"
                        header={
                          <div>
                            <span>대표자명</span>
                            <br />
                            <span>대표전화</span>
                            <br />
                            <span>팩스</span>
                          </div>
                        }
                        body={({ representative, mainPhone, fax }) => (
                          <div>
                            <span>{representative}</span>
                            <br />
                            <span>
                              {mainPhone &&
                                CommonUtil.Formatter.phone(mainPhone)}
                            </span>
                            <br />
                            <span>{fax && CommonUtil.Formatter.fax(fax)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="unionName"
                        header={
                          <div>
                            <span>소속매매조합</span>
                            <br />
                            <span>소속매매단지</span>
                          </div>
                        }
                        body={({ unionName, complexName }) => (
                          <div>
                            <span>{unionName}</span>
                            <br />
                            <span>{complexName}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="address"
                        // style={{minWidth: 120, maxWidth: 260}}
                        header={
                          <div>
                            <span>우편번호</span>
                            <br />
                            <span>주소</span>
                          </div>
                        }
                        body={({
                          shopId,
                          postcode,
                          address,
                          addressDetail,
                        }) => (
                          <>
                            {/*<Tooltip target={`#row-${shopId}`} position="top">*/}
                            {/*  <div>*/}
                            {/*    <span>[{postcode}]</span>*/}
                            {/*    <br />*/}
                            {/*    <span>{address} {addressDetail}</span>*/}
                            {/*  </div>*/}
                            {/*</Tooltip>*/}
                            <div
                              id={`address-${shopId}`}
                              className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                            >
                              <span>[{postcode}]</span>
                              <br />
                              <span>
                                {address} {addressDetail}
                              </span>
                            </div>
                          </>
                        )}
                      />
                      <Column
                        field="shopCode"
                        header={
                          <span>
                            소속점검장
                            <br />
                            점검장코드
                          </span>
                        }
                        body={({ regShopName, regShopCode }) => (
                          <span>
                            {regShopName}
                            {regShopCode && (
                              <>
                                <br />[{regShopCode}]
                              </>
                            )}
                          </span>
                        )}
                      />
                      <Column
                        field="associationCode"
                        header={
                          <span>
                            소속협회
                            <br />
                            협회코드
                          </span>
                        }
                        body={({ regAssociationName, regAssociationCode }) => (
                          <span>
                            {regAssociationName}
                            {regAssociationCode && (
                              <>
                                <br />[{regAssociationCode}]
                              </>
                            )}
                          </span>
                        )}
                      />
                      <Column
                        field="enterpriseCode"
                        header={
                          <span>
                            소속점검업체
                            <br />
                            점검업체코드
                          </span>
                        }
                        body={({ regEnterpriseName, regEnterpriseCode }) => (
                          <span>
                            {regEnterpriseName}
                            {regEnterpriseCode && (
                              <>
                                <br />[{regEnterpriseCode}]
                              </>
                            )}
                          </span>
                        )}
                      />
                      <Column
                        field="useYn"
                        header="사용여부"
                        body={({ useYn }) => columnUseYn(useYn)}
                        bodyClassName="text-center"
                      />
                      {/*<Column field="lastModifiedUserAssociationName" header="최종수정협회" />*/}
                      {/*<Column field="lastModifiedUserEnterpriseName" header="최종수정업체" />*/}
                      {/*<Column field="lastModifiedUserShopName" header="최종수정점검장" />*/}
                      {/*<Column field="lastModifiedUserName" header="최종수정자" />*/}
                      <Column field="note" header="비고" />
                      <Column
                        field="regTime"
                        header={
                          <div>
                            <span>등록사용자</span>
                            <br />
                            <span>등록일시</span>
                          </div>
                        }
                        body={({ regTime, regByUserName }) => (
                          <div>
                            <span>{regByUserName}</span>
                            <br />
                            <span>{columnDateTime(regTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="modTime"
                        header={
                          <div>
                            <span>수정사용자</span>
                            <br />
                            <span>수정일시</span>
                          </div>
                        }
                        body={({ modTime, modByUserName }) => (
                          <div>
                            <span>{modByUserName}</span>
                            <br />
                            <span>{columnDateTime(modTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="delTime"
                        header={
                          <div>
                            <span>삭제사용자</span>
                            <br />
                            <span>삭제일시</span>
                          </div>
                        }
                        body={({ delTime, delByUserName }) => (
                          <div>
                            <span>{columnDateTime(delTime)}</span>
                            <br />
                            <span>{delByUserName}</span>
                          </div>
                        )}
                      />
                    </DataTable>
                  </Panel>

                  {currentCustomerCompanyId !== null && (
                    <CustomerCompanyDialog
                      visible
                      userInfo={myInfoLoadable.contents}
                      customerCompanyId={currentCustomerCompanyId}
                      onHide={async () => {
                        setCurrentCustomerCompanyId(null);
                        await getList(
                          searchConditions,
                          lazyParams.page,
                          lazyParams.rows
                        );
                      }}
                    />
                  )}
                </>
              );

            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = (prevProps, nextProps) => {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CustomerCompanyList, comparisonFn);
