import {
  VEHICLE_TYPE_LEVEL1,
  VEHICLE_TYPE_LEVEL2,
  VEHICLE_TYPE_LEVEL3,
} from '../constants/Constants';

import _ from 'lodash';

const convertPartStatusValue = (value) => {
  let label = '';

  switch (value) {
    case '1':
      label = '';
      break;
    case '2':
      label = 'X';
      break;
    case '3':
      label = 'W';
      break;
    case '4':
      label = 'C';
      break;
    case '5':
      label = 'A';
      break;
    case '6':
      label = 'U';
      break;
    case '7':
      label = 'T';
      break;
    default:
  }

  return label;
};

const performanceCheckUtil = {
  Price: {
    Check: {
      getTypeLabel(madeAt, sortCode, detailModel) {
        const madeAtData = _.find(VEHICLE_TYPE_LEVEL1, { value: madeAt });
        const sortCodeData = _.find(VEHICLE_TYPE_LEVEL2, { value: sortCode });
        const detailModelData = _.find(VEHICLE_TYPE_LEVEL3, {
          value: detailModel,
        });

        return `${madeAtData.label}-${sortCodeData.label}-${detailModelData.label}`;
      },
    },
  },
  Distance: {
    getInsuranceSuffix(distance = 0) {
      const dist = _.toNumber(distance);
      if (dist >= 0 && dist <= 20000) return '0';
      if (dist > 20000 && dist <= 40000) return '1';
      if (dist > 40000 && dist <= 60000) return '2';
      if (dist > 60000 && dist <= 80000) return '3';
      if (dist > 80000 && dist <= 100000) return '4';
      if (dist > 100000 && dist <= 120000) return '5';
      if (dist > 120000 && dist < 130000) return '6';
      // if (dist > 140000 && dist <= 160000) return '7';
      // if (dist > 160000 && dist <= 180000) return '8';
      // if (dist > 180000 && dist <= 200000) return '9';

      return 'X';
    },
  },
  PerformanceStatus: {
    convertForServer(clientData) {
      return _.reduce(
        clientData,
        (res, v, k) => {
          if (k.length === 4 && _.startsWith(k, 's') && k !== 'shop') {
            const value = v === '' || v === '0' ? '1' : v;
            res.push({ code: k, status: value });
          }

          return res;
        },
        []
      );
    },
    convertForClient(serverData) {},
  },
  PartStatus: {
    convertForServer(clientData) {
      return _.reduce(
        clientData,
        (res, v, k) => {
          if (_.startsWith(k, 'pp_') && v !== '') {
            const point = _.replace(k, 'pp_', '');
            res.push({ point, status: convertPartStatusValue(v) });
          }

          return _.filter(res, function (r) {
            return r.status !== '';
          });
        },
        []
      );
    },
  },
  File: {
    convertForServer(clientData) {
      const fileInfo = [];
      const files = [];

      _.map(clientData, (item) => {
        const { partCode, state, photoId, note = null, file } = item;

        fileInfo.push({ photoId, partCode, state, note });
        files.push(file || new File([], 'emptyFile.cerp'));
      });

      // console.log('file data', fileInfo);

      return { fileInfo, files };
    },
    convertForClient(serverData) {},
  },
  Checker: {
    GearBox: {
      isAutomatic(values) {
        return values['GRBX_KND_CODE'] === 'A';
      },
      isManual(values) {
        return values['GRBX_KND_CODE'] === 'M';
      },
    },
    Fuel: {
      isGasoline(values) {
        return values['USE_FUEL_CODE'] === 'a';
      },
      isDiesel(values) {
        return values['USE_FUEL_CODE'] === 'b';
      },
      isLPG(values) {
        return values['USE_FUEL_CODE'] === 'c';
      },
      isElectronic(values) {
        return values['USE_FUEL_CODE'] === 'd';
      },
      isHydrogen(values) {
        return values['USE_FUEL_CODE'] === 'e';
      },
      isHybrid(values) {
        return values['USE_FUEL_CODE'] === 'x';
      },
      isEtc(values) {
        return values['USE_FUEL_CODE'] === 'z';
      },
    },
  },
};

export default performanceCheckUtil;
