import * as Diff from 'deep-object-diff';
import * as ExcelJS from 'exceljs-enhance';
import * as Titled from './../../../components/Atoms/Titled';

import {
  COUNT_PER_PAGE,
  VEHICLE_TYPE_LEVEL1,
  VEHICLE_TYPE_LEVEL2,
  VEHICLE_TYPE_LEVEL3,
} from '../../../constants/Constants';
import { CodeService, ShopCheckPriceService } from './../../../services';
import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import {
  columnDateTime,
  columnEnterpriseType,
  columnUseYn,
} from '../../../utils/dataTableUtil';

import { Badge } from 'primereact/badge';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import Chance from 'chance';
import CheckPriceSaveConfirmDialog from './CheckPriceSaveConfirmDialog';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { SplitButton } from 'primereact/splitbutton';
import { Tag } from 'primereact/tag';
import _ from 'lodash';
import { classNames } from 'primereact/utils';
import dayjs from 'dayjs';
import { myInfoState } from '../../../recoil/selectors';
import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';
import usePrevious from '../../../hooks/usePrevious';
import { useRecoilValue } from 'recoil';

const chance = new Chance();
const defaultValues = {
  priceType: _.get(VEHICLE_TYPE_LEVEL1, '0.value'),
  title: '',
  price: 0,
};

const CheckPriceItemDialog = ({ userInfo, priceItem, onHide = null }) => {
  const isNew = _.isEmpty(priceItem);
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(() => MyInfoUtil.unpack(userInfo), [userInfo]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm(priceItem ? { defaultValues: priceItem } : { defaultValues });
  const currentValues = useWatch({ control });

  const onSubmit = (formData) => {
    if (isNew) onHide(isNew, { ...formData, id: chance.hash() });
    else onHide(isNew, formData);
  };

  return (
    <Dialog
      modal
      maximizable
      breakpoints={{ '960px': '80vw' }}
      visible
      style={{ width: '40vw' }}
      onHide={onHide}
      header="점검료 항목"
      footer={
        <div className="flex flex-row align-items-center justify-content-end">
          {/* <Button
            icon="pi pi-search"
            label="test"
            onClick={() => console.log(currentValues)}
          /> */}
          <Button
            icon={`pi ${isNew ? 'pi-plus' : 'pi-check'}`}
            label={`점검료 ${isNew ? '추가' : '수정'}`}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      }
    >
      <form>
        <div className="grid">
          <div className="col-12">
            <Controller
              control={control}
              name="priceType"
              defaultValue={_.get(VEHICLE_TYPE_LEVEL1, '0.value')}
              render={({ field, fieldState, formState }) => (
                <Titled.RadioButton
                  id={field.name}
                  title="국산/외산 구분"
                  {...field}
                  items={VEHICLE_TYPE_LEVEL1}
                />
              )}
            />
          </div>
          <div className="col-12">
            <div className="field m-0">
              <label>점검료 이름</label>
              <Controller
                control={control}
                name="title"
                defaultValue=""
                rules={{ required: '필수 입력항목입니다.' }}
                render={({ field, fieldState, formState }) => (
                  <InputText id={field.name} {...field} className="w-full" />
                )}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="field m-0">
              <label>점검료</label>
              <Controller
                control={control}
                name="price"
                defaultValue={0}
                rules={{
                  required: '필수 입력항목입니다.',
                  validate: (v) => v >= 0 || '0보다 커야합니다.',
                }}
                render={({ field, fieldState, formState }) => (
                  <InputNumber
                    id={field.name}
                    {..._.omit(field, ['onChange'])}
                    inputRef={field.ref}
                    className="w-full"
                    inputClassName="w-full text-right"
                    onValueChange={(e) => {
                      field.onChange(e.value);
                    }}
                    suffix=" 원"
                    // tooltip="점검료만 할인됩니다."
                    onFocus={(e) => e.target.select()}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default CheckPriceItemDialog;
