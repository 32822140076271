import React, { useEffect, useCallback, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { CommonUtil } from '../../utils/commonUtil';
import { RadioButton } from 'primereact/radiobutton';

const baseUrl = process.env.REACT_APP_BASE_URL;

const SMS_TYPES = {
  PERFORMANCE_CHECK_URL: {
    title: '성능기록부 전송',
    getContent: (smsData) =>
      `${smsData.customerName}고객님, 안녕하세요. 전달 드리는 링크를 통해 성능기록부를 확인하실 수 있습니다.\n${baseUrl}${smsData.url}`,
  },
};

const SendSMS = ({
  onHide,
  smsData,
  setSmsData,
  showSmsType,
  postSms,
  loading,
}) => {
  // 현재 SMS 타입 메모이제이션
  const currentSmsType = useMemo(() => SMS_TYPES[showSmsType], [showSmsType]);

  // 공통 input 핸들러 메모이제이션
  const handleInputChange = useCallback(
    (field) => (e) => {
      setSmsData((prev) => ({ ...prev, [field]: e.target.value }));
    },
    [setSmsData]
  );

  // SMS 내용 생성 로직 메모이제이션
  const makeContent = useCallback(() => {
    if (!currentSmsType) return;

    setSmsData((prev) => ({
      ...prev,
      contents: currentSmsType.getContent(prev),
      msgType: prev.msgType || 'at', // 기본값을 알림톡(at)으로 설정
    }));
  }, [currentSmsType, setSmsData]);

  // SMS 타입이 변경될 때만 내용 업데이트
  useEffect(() => {
    if (showSmsType) {
      makeContent();
    }
  }, [showSmsType, makeContent]);

  // Footer 컴포넌트 메모이제이션
  const footerContent = useMemo(
    () => (
      <div className="flex flex-auto align-items-start justify-content-center pt-2">
        <Button
          label="전송하기"
          icon="pi pi-check-circle"
          className="mr-1"
          onClick={() => postSms(currentSmsType?.title)}
          loading={loading}
        />
      </div>
    ),
    [currentSmsType.title, loading, postSms]
  );

  return (
    <Dialog
      onHide={onHide}
      modal
      visible
      style={{ width: '340px' }}
      header={`메세지 전송 - ${currentSmsType?.title}`}
      footer={footerContent}
    >
      <div>
        <div>고객명: {smsData.customerName}</div>
        <div className="my-2">
          연락처: {CommonUtil.Formatter.phone(smsData.customerContact)}
        </div>
        <Divider />

        <div className="flex flex-column gap-2">
          <div className="flex align-items-center">
            <label className="w-4">수신번호:</label>
            <InputText
              value={CommonUtil.Formatter.phone(smsData.destAddr)}
              onChange={handleInputChange('destAddr')}
              className="p-inputtext-sm flex-1"
            />
          </div>

          <div className="flex align-items-center">
            <label className="w-4">발송번호:</label>
            <InputText
              value={CommonUtil.Formatter.phone(smsData.sourceAddr)}
              onChange={handleInputChange('sourceAddr')}
              className="p-inputtext-sm flex-1"
            />
          </div>
        </div>

        <div className="flex align-items-center mt-1">
          <label className="w-4">발송방법:</label>
          <div className="flex align-items-center mt-1">
            <RadioButton
              inputId="at"
              name="messageType"
              value="at"
              checked={smsData.msgType === 'at'}
              onChange={handleInputChange('msgType')}
            />
            <label htmlFor="at" className="mr-3 ml-1">
              알림톡
            </label>
            <RadioButton
              inputId="lms"
              name="messageType"
              value="lms"
              checked={smsData.msgType === 'lms'}
              onChange={handleInputChange('msgType')}
            />
            <label htmlFor="lms" className="mr-1 ml-1">
              문자
            </label>
          </div>
        </div>

        <Divider />

        <div>
          <div>발송내역</div>
          <InputTextarea
            className="w-full mt-1"
            rows={4}
            autoResize
            value={smsData?.contents || ''}
            onChange={handleInputChange('contents')}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default SendSMS;
