import * as AccountDialog from './../../components/Dialog/AccountDialog';
import * as Button from './../../components/Atoms/Button';

import React, { useEffect, useMemo, useState } from 'react';
import { columnDateTime, columnUseYn } from '../../utils/dataTableUtil';

import { COUNT_PER_PAGE } from '../../constants/Constants';
import { CodeUtil } from '../../utils/CodeUtil';
import { Column } from 'primereact/column';
import { CommonUtil } from '../../utils/commonUtil';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import SearchMenu from '../../components/Menu/SearchMenu';
import { ServiceProvider } from '../../services';
import { YN } from '../../constants/Constants';
import _ from 'lodash';
import { myInfoSelector } from '../../recoil/selectors';
import { useAuthorizedFetch } from '../../hooks/useAuthorizedFetch';
import { useRecoilValueLoadable } from 'recoil';

const DEFAULT_SEARCH_CONDITIONS = {
  aids: [],
  searchText: '',
  useYN: YN.YES,
};

const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
  sortField: null,
  sortOrder: null,
  filters: null,
};

const partnerService = ServiceProvider.partner;

const PartnerList = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(
    () => MyInfoUtil.unpack(myInfoLoadable.contents),
    [myInfoLoadable.contents]
  );

  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
    aids: [myAssociationId],
  });
  const [lazyParams, setLazyParams] = useState({
    ...DEFAULT_LAZY_PARAMS,
  });
  const { loading, totalRecords, data, getList } = useAuthorizedFetch(
    partnerService,
    searchConditions,
    setSearchConditions,
    DEFAULT_SEARCH_CONDITIONS,
    lazyParams.page,
    lazyParams.rows
  );

  const [codes, setCodes] = useState({});
  const [currentPartnerId, setCurrentPartnerId] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        if (myInfoLoadable.state === 'hasValue') {
          const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
          const [associations, associationId] =
            await CodeUtil.getAssociationCodes(myInfo, {
              preSelectedAssociationId: myAssociationId,
            });
          setCodes((ps) => ({ ...ps, associations }));
          setSearchConditions({ ...searchConditions, aids: [associationId] });
          await getList({
            ...searchConditions,
            aids: [associationId],
          });
        }
      } catch (err) {
        console.log(err);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.state, myInfoLoadable.contents]);

  const deletePartner = async (id) => {
    try {
      const data = await partnerService.delete(id);
      if (data) {
        window.cerp.toast.success(
          '진단점 삭제 완료',
          `[${data.partnerInfo.partnerName}] 진단점이 삭제되었습니다.`
        );
        getList({
          ...DEFAULT_SEARCH_CONDITIONS,
          aids: [myAssociationId],
        });
        setCurrentPartnerId(null);
      }
    } catch (error) {
      console.log(error);
      window.cerp.dialog.error(
        '진단점 삭제 실패',
        `[${error?.code}] ${error?.message}`
      );
      setCurrentPartnerId(null);
    }
  };

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);

              return (
                <>
                  <SearchMenu
                    onInit={
                      !!searchConditions.searchText
                        ? () => {
                            setSearchConditions({
                              ...searchConditions,
                              searchText: '',
                            });
                            getList({
                              ...DEFAULT_SEARCH_CONDITIONS,
                              aids: [myAssociationId],
                            });
                          }
                        : null
                    }
                    onSearch={() =>
                      searchConditions.searchText &&
                      getList(
                        searchConditions,
                        _.get(DEFAULT_LAZY_PARAMS, 'page'),
                        _.get(DEFAULT_LAZY_PARAMS, 'rows')
                      )
                    }
                  >
                    {/* <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="majorLocations">지역 대분류</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.majorLocations}
                        display="chip"
                        placeholder="전체 지역 대분류"
                        value={searchConditions.majorLocations}
                        filter
                        onChange={async ({ value }) => {
                          const [enterprises, enterpriseId, enterprise] =
                            await CodeUtil.getEnterpriseCodes(myInfo, {
                              associationId: value,
                            });
                          setCodes((ps) => ({ ...ps, enterprises }));
                          setSearchConditions((ps) => ({
                            ...ps,
                            majorLocations: value,
                            minorLocations: [],
                          }));
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="minorLocations">지역 소분류</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.minorLocations}
                        display="chip"
                        placeholder="전체 지역 소분류"
                        value={searchConditions.minorLocations}
                        filter
                        showClear
                        onChange={({ value }) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            minorLocations: value,
                          }));
                        }}
                      />
                    </div> */}
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="search_text">진단점명</label>
                      <div className="flex">
                        <InputText
                          className="w-full"
                          value={searchConditions.searchText}
                          placeholder="진단점 이름으로 검색"
                          onChange={({ target: { value } }) => {
                            setSearchConditions((ps) => ({
                              ...ps,
                              searchText: value,
                              aids: [myInfo.myAssociationId],
                            }));
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              getList(
                                searchConditions,
                                DEFAULT_LAZY_PARAMS.page,
                                DEFAULT_LAZY_PARAMS.rows
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </SearchMenu>

                  <div className="card flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
                    <Button.Default
                      label="진단점 신규 등록"
                      icon="pi pi-check-square"
                      style={{ marginLeft: 'auto' }}
                      onClick={() => {
                        setCurrentPartnerId(0);
                      }}
                    />
                  </div>

                  <Panel className="shadow-1 mb-3">
                    <DataTable
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={totalRecords}
                      onPage={async (event) => {
                        setLazyParams(event);
                        await getList(searchConditions, event.page, event.rows);
                      }}
                      paginator
                      responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      loading={loading}
                      emptyMessage="데이터가 없습니다."
                      onRowClick={(e) => setCurrentPartnerId(e.data.partnerId)}
                      className="cursor-pointer"
                      stripedRows
                    >
                      <Column
                        header="순번"
                        align={'center'}
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        field="partnerName"
                        header="진단점명"
                        bodyClassName="text-center"
                        body={({ partnerName }) => <span>{partnerName}</span>}
                      />
                      <Column
                        field="mainPhone"
                        header="대표전화"
                        bodyClassName="text-center"
                        body={({ mainPhone }) => (
                          <span>
                            {mainPhone && CommonUtil.Formatter.phone(mainPhone)}
                          </span>
                        )}
                      />
                      <Column
                        field="cellphone"
                        header="휴대전화"
                        bodyClassName="text-center"
                        body={({ cellphone }) => (
                          <span>
                            {cellphone &&
                              CommonUtil.Formatter.cellphone(cellphone)}
                          </span>
                        )}
                      />
                      {/* <Column
                        field="majorLocation"
                        header="지역 대분류"
                        bodyClassName="text-center"
                        body={({ majorLocation }) => (
                          <span>{majorLocation}</span>
                        )}
                      />
                      <Column
                        field="minorLocation"
                        header="지역 소분류"
                        bodyClassName="text-center"
                        body={({ minorLocation }) => (
                          <span>{minorLocation}</span>
                        )}
                      /> */}
                      <Column
                        align={'center'}
                        field="reliability"
                        header="신뢰도"
                        body={({ reliability }) => (
                          <span>
                            {reliability === '1'
                              ? '상'
                              : reliability === '2'
                              ? '중'
                              : '하'}
                          </span>
                        )}
                      />
                      <Column
                        field="address"
                        header="주소"
                        body={({
                          partnerId,
                          postcode,
                          address,
                          addressDetail,
                        }) => (
                          <>
                            <div
                              id={`address-${partnerId}`}
                              className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                            >
                              <span>[{postcode}]</span>
                              <br />
                              <span>
                                {address} {addressDetail}
                              </span>
                            </div>
                          </>
                        )}
                      />
                      <Column
                        field="businessRegistrationNumber"
                        header="사업자등록번호"
                        body={({ businessRegistrationNumber }) => {
                          const bizNumber = businessRegistrationNumber.slice(
                            0,
                            10
                          );
                          return (
                            <span>{CommonUtil.Formatter.biz(bizNumber)}</span>
                          );
                        }}
                      />
                      <Column
                        field="bankAccountNumber"
                        header="통장번호"
                        body={({ bankName, bankAccountNumber }) => (
                          <div>
                            <p>{bankName}</p>
                            <p>{bankAccountNumber}</p>
                          </div>
                        )}
                      />
                    </DataTable>
                  </Panel>

                  {currentPartnerId !== null && (
                    <AccountDialog.Partner
                      visible
                      userInfo={myInfoLoadable.contents}
                      partnerId={currentPartnerId}
                      onHide={() => {
                        setCurrentPartnerId(null);
                        getList({
                          ...DEFAULT_SEARCH_CONDITIONS,
                          aids: [myAssociationId],
                        });
                      }}
                      deletePartner={deletePartner}
                    />
                  )}
                </>
              );

            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

export default PartnerList;
